import format from "date-fns/format";
import logger from "@olivahealth/logger/client";

export const DATE_FORMAT = "EEEE, d MMMM";
export const DATE_FORMAT_WITH_YEAR = "EEEE, d MMMM yyyy";
export const SHORT_DATE_FORMAT = "E dd, MMM";
export const MINIMAL_DATE_FORMAT = "do MMM";
export const NUMERICAL_DAY_MONTH_FORMAT = "dd/MM";
export const MINIMAL_DATE_FORMAT_FULL_MONTH_YEAR = "d MMMM yyyy";
export const MINIMAL_DATE_FORMAT_FULL_MONTH = "do MMMM";
export const MINIMAL_DATE_FORMAT_MONTH_YEAR = "MM / yy";
export const TIME_FORMAT = "HH:mm";
export const DAY_MONTH_YEAR_FORMAT = "dd/MM/yyyy";

export const DATE_TIME_FORMAT = `${DATE_FORMAT} 'at' ${TIME_FORMAT}`;
export const SHORT_DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT} 'at' ${TIME_FORMAT}`;
export const SHORT_DATE_TIME_FORMAT_YEAR = `${MINIMAL_DATE_FORMAT_FULL_MONTH_YEAR} 'at' ${TIME_FORMAT}`;
export const MINIMAL_DATE_TIME_FORMAT = `${MINIMAL_DATE_FORMAT} 'at' ${TIME_FORMAT}`;

/**
 * Start end date format e.g.
 * Monday 3 January - Friday 7 January
 * EEEE d MMMM - EEEE d MMMM
 */
export function formatStartEndDate(
  startDate: string | Date,
  endDate?: string | Date | null,
): string {
  try {
    const startText = format(getDateInstance(startDate), `${DATE_FORMAT}`);
    if (!endDate) {
      return startText;
    }

    const endText = format(getDateInstance(endDate), `${DATE_FORMAT}`);
    return `${startText} - ${endText}`;
  } catch (error) {
    logError("formatStartEndDate", error as Error, { startDate, endDate });
    return "Invalid Date";
  }
}

/**
 * Start end date time format e.g.
 * Monday 3 January at 17:00 - 18:00
 * EEEE d MMMM 'at' HH:mm - HH:mm
 */
export function formatStartEndDateTime(
  startDate: string | Date,
  endDate: string | Date,
): string {
  try {
    const startText = format(
      getDateInstance(startDate),
      `${DATE_FORMAT} 'at' ${TIME_FORMAT}`,
    );
    return `${startText} - ${formatTime(endDate)}`;
  } catch (error) {
    logError("formatStartEndDateTime", error as Error, { startDate, endDate });
    return "Invalid Date";
  }
}

/**
 * Minimal start end date time format e.g.
 * 2nd Jan at 17:00 - 18:00
 * do MMM 'at' HH:mm - HH:mm OO
 */
export function formatMinimalStartEndDateTime(
  startDate: string | Date,
  endDate: string | Date,
): string {
  try {
    const startText = format(
      getDateInstance(startDate),
      `${MINIMAL_DATE_FORMAT} 'at' ${TIME_FORMAT}`,
    );
    return `${startText} - ${formatTime(endDate)}`;
  } catch (error) {
    logError("formatMinimalStartEndDateTime", error as Error, {
      startDate,
      endDate,
    });
    return "Invalid Date";
  }
}

/**
 * Date time format e.g.
 * Monday 3 January at 17:00
 * EEEE d MMMM 'at' HH:mm OO
 */
export function formatDateTime(date: string | Date): string {
  try {
    return format(getDateInstance(date), DATE_TIME_FORMAT);
  } catch (error) {
    logError("formatDateTime", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Short date time e.g.
 * Mon, Jan 3 at 17:00
 * E, MMM d 'at' HH:mm
 */
export function formatShortDateTime(date: string | Date): string {
  try {
    return format(getDateInstance(date), SHORT_DATE_TIME_FORMAT);
  } catch (error) {
    logError("formatShortDateTime", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Short date time with year e.g.
 * 3 Jan 2023 at 17:00
 * E, MMM d 'at' HH:mm
 */
export function formatShortDateTimeYear(date: string | Date): string {
  try {
    return format(getDateInstance(date), SHORT_DATE_TIME_FORMAT_YEAR);
  } catch (error) {
    logError("formatShortDateTimeYear", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Minimal date time e.g.
 * 3rd Jan at 17:00
 * do MMM 'at' HH:mm
 */
export function formatMinimalDateTime(date: string | Date): string {
  try {
    return format(getDateInstance(date), MINIMAL_DATE_TIME_FORMAT);
  } catch (error) {
    logError("formatMinimalDateTime", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Date format e.g.
 * Monday, 3 January
 * EEEE, d MMMM
 */
export function formatDate(date: string | Date, includeYear?: boolean): string {
  try {
    return format(
      getDateInstance(date),
      includeYear ? DATE_FORMAT_WITH_YEAR : DATE_FORMAT,
    );
  } catch (error) {
    logError("formatDate", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Short date e.g.
 * Mon, Jan 3
 * E, MMM d
 */
export function formatShortDate(date: string | Date): string {
  try {
    return format(getDateInstance(date), SHORT_DATE_FORMAT);
  } catch (error) {
    logError("formatShortDate", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Minimal date e.g.
 * 1st Jan
 * do MMM
 */
export function formatMinimalDate(date: string | Date): string {
  try {
    return format(getDateInstance(date), MINIMAL_DATE_FORMAT);
  } catch (error) {
    logError("formatMinimalDate", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Minimal date with full month & year e.g.
 * 1 January 2023
 * d MMMM yyyy
 */
export function formatMinimalDateFullMonthYear(date: string | Date): string {
  try {
    return format(getDateInstance(date), MINIMAL_DATE_FORMAT_FULL_MONTH_YEAR);
  } catch (error) {
    logError("formatMinimalDateFullMonthYear", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Minimal date with full month e.g.
 * 1st January
 * do MMMM
 */
export function formatMinimalDateFullMonth(date: string | Date): string {
  try {
    return format(getDateInstance(date), MINIMAL_DATE_FORMAT_FULL_MONTH);
  } catch (error) {
    logError("formatMinimalDateFullMonth", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Numerical short date e.g.
 * 01/01
 * dd/MM
 */
export function formatDayMonthDate(date: string | Date): string {
  try {
    return format(getDateInstance(date), NUMERICAL_DAY_MONTH_FORMAT);
  } catch (error) {
    logError("formatDayMonthDate", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Numerical short date with year e.g.
 * 01/01/2001
 * dd/MM/yyyy
 */
export function formatDayMonthYearDate(date: string | Date): string {
  try {
    return format(getDateInstance(date), DAY_MONTH_YEAR_FORMAT);
  } catch (error) {
    logError("formatDayMonthYearDate", error as Error, { date });
    return "Invalid Date";
  }
}

/**
 * Time e.g.
 * 17:00
 * HH:mm
 */
export function formatTime(date: string | Date): string {
  try {
    return format(getDateInstance(date), TIME_FORMAT);
  } catch (error) {
    logError("formatTime", error as Error, { date });
    return "Invalid Date";
  }
}

function getDateInstance(date: string | Date): Date {
  try {
    return date instanceof Date ? date : new Date(date);
  } catch (error) {
    logError("getDateInstance", error as Error, { date });
    // Return current date so the UI will does not break
    // It will display the wrong information and we will be alerted
    return new Date();
  }
}

function logError(functionName: string, error: Error, data?: object) {
  logger.error(
    "utils/date/format",
    `Failed to format provided Date in ${functionName}`,
    {
      ...data,
      errorStack: error.stack,
      errorMessage: error.message,
    },
  );
}

/**
 * Time range format e.g.
 * 11:30 - 12:00
 * HH:mm-HH:mm
 */
export function formatTimeRange(
  startDate: string | Date,
  endDate: string | Date,
): string {
  try {
    const start = getDateInstance(startDate);
    const end = getDateInstance(endDate);

    const formattedStart = format(start, TIME_FORMAT);
    const formattedEnd = format(end, TIME_FORMAT);

    return `${formattedStart} - ${formattedEnd}`;
  } catch (error) {
    logError("formatTimeRange", error as Error, { startDate, endDate });
    return "Invalid Time Range";
  }
}
